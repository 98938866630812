<template>
  <Main>
    <div class="settings">
      <div class="settings__container">
        <h1>Настройки</h1>
        <p class="settings__label">Цена грузчика за час, грн</p>
        <div class="settings__contents">
          <InputText
            :set-value="formDataToSend"
            :id="'rateMovers'"
            :label="'rateMovers'"
            :type="'number'"
            :placeholder="'Ввести ставку'"
            :error-messages="error['loaderRatesPerHour']"
            isBordered
          ></InputText>
          <p class="settings__label">Ввести номер поддержки</p>
          <InputText
            :set-value="formDataToSend"
            :id="'phone'"
            :label="'phone'"
            :type="'text'"
            :placeholder="'Телефон'"
            :error-messages="error['phone']"
            :thisPhone="true"
            isBordered
          ></InputText>
          <p class="settings__label">Водитель может начать заказ за, минуты</p>
          <InputText
            :set-value="formDataToSend"
            :id="'minutesAfterOrderWillCancelled'"
            :label="'minutesAfterOrderWillCancelled'"
            :type="'number'"
            :placeholder="'Ввести время'"
            :error-messages="error['minutesAfterOrderWillCancelled']"
            isBordered
          ></InputText>
          <p class="settings__label">Заказ считается просроченым, минуты</p>
          <InputText
            :set-value="formDataToSend"
            :id="'minutesBeforeOrderCanBeStarted'"
            :label="'minutesBeforeOrderCanBeStarted'"
            :type="'number'"
            :placeholder="'Ввести время'"
            :error-messages="error['minutesBeforeOrderCanBeStarted']"
            isBordered
          ></InputText>

          <ButtonMain @click.native="handleOpenConfirmModal">Сохранить</ButtonMain>
        </div>
      </div>
    </div>
    <DefaultModal v-if="updateModal" :on-close="handleCloseConfirmModal">
      <ConfirmModalContent :on-cancel="handleCloseConfirmModal" :on-success="handleSendSettings">
        <template v-slot:description>
          <p>Вы уверены, что хотите изменить настройки?</p>
        </template>
        <template v-slot:cancel> Отмена </template>
        <template v-slot:success> Да </template>
      </ConfirmModalContent>
    </DefaultModal>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import { phoneNumberIteration } from "../helpers/dataIteration";
import settingsApi from "../api/settings";

import Main from "../templets/Main.vue";
import InputText from "../components/atoms/inputs/InputText.vue";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
import FetchSpinnerModal from "../components/molecules/FetchSpinnerModal.vue";
import DefaultModal from "../components/molecules/DefaultModal.vue";
import ConfirmModalContent from "../components/molecules/ConfirmModalContent.vue";

export default {
  name: "Settings",
  data() {
    return {
      formDataToSend: {
        rateMovers: null,
        phone: null,
        minutesAfterOrderWillCancelled: null,
        minutesBeforeOrderCanBeStarted: null,
      },
      updateModal: false,
    };
  },
  methods: {
    handleSendSettings() {
      const data = {
        loader_rates_per_hour: this.formDataToSend.rateMovers,
        support_phone: phoneNumberIteration(this.formDataToSend.phone),
        minutes_after_order_will_cancelled: this.formDataToSend.minutesAfterOrderWillCancelled,
        minutes_before_order_can_be_started: this.formDataToSend.minutesBeforeOrderCanBeStarted,
      };
      this.$store.dispatch("settings/updateSettings", data);
      this.handleCloseConfirmModal();
    },
    handleCloseConfirmModal() {
      this.updateModal = false;
    },
    handleOpenConfirmModal() {
      this.updateModal = true;
    },
  },
  components: {
    Main,
    InputText,
    ButtonMain,
    FetchSpinnerModal,
    DefaultModal,
    ConfirmModalContent,
  },
  computed: {
    ...mapState("settings", ["error", "settings", "loadingProcess"]),
  },
  mounted() {
    this.$store.commit("settings/setLoading", true);
    settingsApi
      .getSettings()
      .then((res) => {
        this.formDataToSend.rateMovers = res.data[0].value;
        this.formDataToSend.phone = res.data[1].value;
        this.formDataToSend.minutesAfterOrderWillCancelled = res.data[2].value;
        this.formDataToSend.minutesBeforeOrderCanBeStarted = res.data[3].value;
      })
      .catch((error) => {
        const { data } = error.response;
        this.$store.commit("settings/setError", data);
      })
      .finally(() => {
        this.$store.commit("settings/setLoading", false);
      });
  },
};
</script>

<style lang="scss" scoped>
.settings {
  &__container {
    padding: 21px 17px;
  }

  &__contents {
    width: 328px;
  }

  &__label {
    font-size: $g-font-base-size;
    color: $mainSecondTextColor;
    padding: 27px 0 7px;
  }

  /deep/.button-main {
    margin-top: 10px;
  }
}
</style>
